<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("users.heading.edit") }}</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("users.subheading.edit") }}</span>
      </div>
      <div class="card-toolbar">
        <b-button class="btn btn-success mr-2" @click.prevent="editProfile" ref="save_submit">{{ $t("button.save") }}</b-button>
      </div>
    </div>
    <!--end::Header-->
     <div class="card-body" v-if="!loading">
        <!--begin::Body-->
      <ValidationObserver ref="form">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="degree_before">{{ $t("label.degree_before") }}</label>
          <div class="col-lg-3 col-xl-2">
            <input
              ref="degree_before"
              class="form-control form-control-lg form-control-solid"
              type="text"
              id="degree_before"
              v-model="user.degree_before"
            />
          </div>
          <label class="col-xl-2 col-lg-3 col-form-label text-right" for="degree_after">{{ $t("label.degree_after") }}</label>
          <div class="col-lg-3 col-xl-2">
            <input
              ref="degree_after"
              class="form-control form-control-lg form-control-solid"
              type="text"
              id="degree_after"
              v-model="user.degree_after"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="name">{{ $t("label.name") }}</label>
          <div class="col-lg-9 col-xl-6">            
            <ValidationProvider name="name" immediate rules="required|min:3" v-slot="{ errors }">
              <input 
                v-model="user.name" 
                type="text" 
                class="form-control form-control-lg form-control-solid"
                v-bind:placeholder="$t('placeholder.name')"
                >                
                <span class="text-danger">{{ errors[0] }}</span>  
            </ValidationProvider>    
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="surname">{{ $t("label.surname") }}</label>
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider immediate rules="required|min:3" v-slot="{ errors }">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:placeholder="$t('placeholder.surname')"
                v-model="user.surname"
              />
              <span class="text-danger">{{ errors[0] }}</span>  
            </ValidationProvider>    
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">{{ $t("users.title.contact_info") }}</h5>
          </div>
        </div>
        
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="email">{{ $t("label.email") }}</label
          >
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider immediate rules="required|min:3|email" v-slot="{ errors }">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="email"
                class="form-control form-control-lg form-control-solid"
                id="email"
                v-bind:placeholder="$t('placeholder.email')"
                v-model="user.email"
              />
            </div>
            <span class="text-danger">{{ errors[0] }}</span>  
            </ValidationProvider>  
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="phone">{{ $t("label.phone") }}</label>
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider immediate rules="min:9|max:9|numeric" v-slot="{ errors }">
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la la-phone"></i>
                  </span>
                </div>
                <input
                  ref="phone"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  id="phone"
                  v-bind:placeholder="$t('placeholder.phone')"
                  v-model="user.phone"
                />
              </div>
              <span class="text-danger">{{ errors[0] }}</span>  
              <span class="form-text text-muted">{{ $t("placeholder.phone_format") }}</span>
            </ValidationProvider>  
            
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">{{ $t("users.title.additional_info") }}</h5>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="sex">{{ $t("label.sex") }}</label>
          <div class="col-lg-9 col-xl-6">
              <vs-select v-model="user.sex" :options="sex" :clearable="false"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="position_id">{{ $t("label.position") }}</label>
          <div class="col-lg-9 col-xl-6">
             <vs-select v-model="user.position" :options="positions" label="name" v-if="positions" :clearable="false"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="language_id">{{ $t("label.language") }}</label>
          <div class="col-lg-9 col-xl-6">
            <vs-select v-model="user.language" :options="languages" label="name" v-if="languages" :clearable="false"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right" for="pin">{{ $t("label.pin") }}</label>
          <div class="col-lg-9 col-xl-6">
            <ValidationProvider immediate rules="min:4" v-slot="{ errors }">
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la la-lock"></i>
                  </span>
                </div>
                <input
                  ref="pincode"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  id="pincode"
                  v-bind:placeholder="$t('placeholder.pin')"
                  v-model="user.pincode"
                />
              </div>
              <span class="text-danger">{{ errors[0] }}</span>  
            </ValidationProvider>  
          </div>
        </div>
        </ValidationObserver>
      <!--end::Body-->
     </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import { UPDATE_CURRENT_DATA } from "@/core/services/store/auth.module";

export default {
  name: "user_edit",
  data() {
    return {
      loading: false,
      //user: null,
      languages: null,
      positions: null,
      sex: ['MALE','FEMALE'],
    };
  },
  props: {
    user: null,
  },
  beforeMount() {
    this.getLanguages();
    this.getPositions();
  },
  metaInfo () { 
      return { title: this.$t("users.meta.subtitle.edit")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("users.meta.title_profile"), subtitle: this.$t("users.meta.subtitle.edit") }]);
  },
  methods: {
    getLanguages() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/languages")
        .then((response) => {
          this.languages = response.data.data;
          this.loading = false;
        });
    },
    getPositions() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/positions")
        .then((response) => {
          this.positions = response.data.data;
          this.loading = false;
        });
    },
    editProfile(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 
        ApiService.apiPost("/profil/update",
        { 
          'degree_before': this.user.degree_before,
          'degree_after': this.user.degree_after,
          'name': this.user.name,
          'surname': this.user.surname,
          'email': this.user.email,
          'phone': this.user.phone,
          'sex': this.user.sex,
          'position_id': this.user.position.position_id,
          'language_id': this.user.language.language_id,
          'pincode': this.user.pincode,
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$parent.getUser();
            this.$store.dispatch(UPDATE_CURRENT_DATA);
        }).catch(error => {
            checkErrors(error);
        });
      });
    }
  }
};
</script>
